import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Hero from '../../components/hero'
import Boilerplate from '../../components/boilerplate'

const IndexPage = () => (
  <Layout lang="en">
    <SEO title="Imprint" keywords={['creopharm', 'pharma', 'co-packing']} />

    <Hero lang="en" />

    <div className="container medium-padding">
      <h2>Imprint</h2>
      <p>
        <strong>Creopharm GmbH</strong>
        <br />
        Bielefelder Str. 21 a<br />
        49124 Georgsmarienhütte, Germany
        <br />
        Telefon: +49 (0) 5401 8814 – 0<br />
        Telefax: +49 (0) 5401 8814 – 100
        <br />
        E-Mail: info@creopharm.de
        <br />
        Website: <a href="http://www.creopharm.de/">http://www.creopharm.de</a>
      </p>

      <p>
        Company registration: Osnabrück (Germany)
        <br />
        company registry no. HRB 201427
        <br />
        Managing Partner: Klaus Gustenberg
        <br />
        Registered with the German tax authorities at Finanzamt Osnabrück-Stadt
        with VAT/fiscal ID number DE 256139813
      </p>

      <p>
        <strong>Legal warning:</strong>
        <br />
        All the information and details posted on this website have been
        carefully researched and verified. The information concerned is,
        however, a general service only, and no substitute for personal
        consultation. Neither the operator of this website nor any third-party
        suppliers accept any liability whatsoever for the correctness,
        completeness and/or updated nature of the information concerned.
      </p>

      <p>
        All the details concerned are supplied exclusively for the information
        of visitors to the website. Liability is otherwise limited to
        misrepresentation and gross negligence. The respective providers shall
        likewise accept no liability whatsoever for the websites or web pages of
        third parties accessed via hyperlinks. The operator of this website is
        not responsible for the content of such third-party web pages. We wish
        to state expressly that we have no influence over the design or content
        of such linked web pages. We therefore expressly disclaim responsibility
        for all external pages and content accessed via links on our website,
        and make plain that we have no connection with the content of these
        websites.
      </p>

      <p>
        This declaration applies to all links displayed on our website and to
        all content to which banners, graphics and links on our website offer
        access. This website may furthermore be accessed via hyperlinks on other
        web pages without the knowledge of its operator. The operator shall
        accept no liability whatsoever for the content or presentation of any
        link posted on third-party websites.
      </p>

      <p>
        The operator furthermore reserves the right to amend or add to the
        information provided. None of the subject areas described in this
        documentation shall constitute a basis for legal claims of any kind.
      </p>

      <p>
        The contents and structural design of this website are protected by
        copyright. The copying of content or data, especially but not limited to
        the use of text, whether in whole or in part, or graphics, shall require
        the express advance consent of the operator and its corresponding
        partners. This content, whether in part or in whole, may not be
        duplicated and/or copied and/or published and/or stored on any
        information retrieval system of any kind without the previous written
        consent of the copyright holder.
      </p>

      <p>
        All personal data are treated as confidential at all times. We adhere to
        the statutory provisions of the Federal Data Protection Act (BDSG) and
        German Telemedia Act (TMG) respectively when collecting, processing and
        using data and individual details. You may at any time contest the use,
        processing and/or transmission of your data for marketing purposes, or
        cancel your existing consent, by contacting us in writing at the above
        address.
      </p>
    </div>

    <Boilerplate lang="en" />
  </Layout>
)

export default IndexPage
